import Text from '@atoms/Text';
import VisibilitySensor from 'react-visibility-sensor';
import DesktopViewCard from './desktopViewCard';
import MobileViewCard from './mobileViewCard';
import texts from '@components/Homepage/Investors/en.json';
import Image from '@atoms/Image';

function Investors({
    backgroundColor = 'bg-basicWhite',
    paddingClass = 'py-12 md:py-28',
    primaryText = texts?.BackedByTopInvestors,
    secondaryText = texts?.TrustedBySomeOfTheMostWellKnowEntrepreneurs,
    bgAndBorderColor = 'bg-primary-10 border-primary-30',
    primaryTextWidth = ''
}) {

    return (
        <VisibilitySensor partialVisibility>
            {({ isVisible }) => (
                <section
                    className={`${backgroundColor} investors-section ${paddingClass} flex flex-col justify-center  z-[80] relative`}
                >
                    <div
                        className={`flex flex-col justify-center container w-full mx-auto lg:px-28 xl:px-20 md:px-0 px-5 relative z-50 gap-6`}
                    >
                        <div className="flex flex-col items-center gap-6 md:gap-8">
                            <Text
                                content={primaryText}
                                className={`text-center text-primary-500 h4-semibold md:h3-semibold ${primaryTextWidth}`}
                                htmlTag='h2'
                            />
                            <div className="flex justify-center items-center gap-5 md:gap-12 xl:gap-[52px] xl:mb-2">
                                <div className="w-[83px] h-[26px] md:w-[141px] md:h-[44px]">
                                    <Image
                                        src="/images/homepage/investors/accel.svg"
                                        alt="Accel"
                                        className="relative w-full h-full opacity-60"
                                    />
                                </div>
                                <div className="w-[1px] h-[38px] md:h-16 bg-gray-200" />
                                <div className="w-[196px] h-[47px] md:w-[333px] md:h-[80px]">
                                    <Image
                                        src="/images/homepage/investors/harmonypartners.webp"
                                        alt="Harmony Partners"
                                        className="relative w-full h-full"
                                    />
                                </div>
                            </div>
                            <Text
                                content={secondaryText}
                                htmlTag="p"
                                isInnerHtml
                                className="p5-regular md:p4-regular md:mb-8 md:mx-4 xl:mx-8 text-basicBlack text-center"
                            />
                        </div>
                        <div className="overflow-hidden invertors-list md:flex flex-col justify-center">
                            <div className="md:block hidden">
                                <DesktopViewCard
                                    bgAndBorderColor={bgAndBorderColor}
                                />
                            </div>
                            <div className="md:hidden block">
                                <MobileViewCard
                                    isVisible={isVisible}
                                    bgAndBorderColor={bgAndBorderColor}
                                />
                            </div>
                        </div>
                    </div>
                </section>
            )}
        </VisibilitySensor>
    );
}

export default Investors;
