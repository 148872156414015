import { Text } from '@atoms/index';
import { ourInvestors } from '@helpers/homepageData';
import LazyImage from '@atoms/LazyImage';

function DesktopViewCard({ bgAndBorderColor }) {
    return (
        <ul className="flex justify-center flex-wrap gap-8 xl:gap-0 md:mx-20 xl:mx-0">
            {ourInvestors.map((data, index) => {
                return (
                    <li
                        key={index}
                        className={`md:w-auto w-full list-none flex`}
                    >
                        <div
                            className={`flex md:flex-col  flex-row md:w-[160px] w-[80%]  sm:rounded-[20px] rounded-[12px] xl:mr-[32px] md:my-[0px] my-[5px] items-center border ${bgAndBorderColor}`}
                        >
                            <div className="sm:p-5 p-[10px] flex justify-center items-center">
                                <LazyImage
                                    src={data.image}
                                    alt={data?.alt ? data?.alt : data.name}
                                    className="relative rounded-lg overflow-hidden xl:w-[120px] xl:h-[120px] sm:w-[80px] sm:h-[80px] w-[60px] h-[60px]"
                                />
                            </div>
                            <div className="md:ml-0 md:flex-1 sm:ml-3 ml-[15px] md:text-center pb-8">
                                <Text
                                    content={data.name}
                                    htmlTag="h6"
                                    className="p5-semibold flex justify-center items-start mb-2 text-basicBlack md:text-center"
                                />
                                <Text
                                    content={data.position}
                                    htmlTag="span"
                                    className="p6-regular flex justify-center items-start text-gray-900 md:text-center"
                                />
                            </div>
                        </div>
                    </li>
                );
            })}
        </ul>
    );
}

export default DesktopViewCard;
