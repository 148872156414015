import { Text } from '@atoms/index';
import { ourInvestors } from '@helpers/homepageData';
import LazyImage from '@atoms/LazyImage';

function MobileViewCard({
    isVisible,
    bgAndBorderColor,
}) {
    return (
        <ul>
            {ourInvestors.map((data, index) => {
                return (
                    <li
                        key={index}
                        className={`md:w-auto w-full list-none flex ${
                            isVisible ? `${data.cssClasses}` : ''
                        }  `}
                        data-animation={data.animateClass}
                    >
                        <div
                            className={`flex md:flex-col  flex-row sm:p-5 p-[10px] md:w-[160px] w-[80%]  sm:rounded-[20px] rounded-[12px] md:mr-[50px] md:my-[0px] my-[8px] items-center border ${bgAndBorderColor}`}
                        >
                            <LazyImage
                                src={data.image}
                                alt={data?.alt ? data?.alt : data.name}
                                className="relative md:mb-[15px] rounded-lg overflow-hidden xl:w-[120px] xl:h-[120px] sm:w-[80px] sm:h-[80px] w-[60px] h-[60px]"
                            />
                            <div className="md:ml-0 md:flex-1 sm:ml-3 ml-[15px] md:text-center leading-[1px]">
                                <Text
                                    content={data.name}
                                    htmlTag="h6"
                                    className="p5-semibold flex justify-center items-start h-[50%]   md:mb-[0px] mb-[5px] text-basicBlack md:text-center"
                                />
                                <Text
                                    content={data.position}
                                    htmlTag="span"
                                    className="p6-regular flex justify-center items-start h-[50%] not-italic text-gray-900 md:text-center"
                                />
                            </div>
                        </div>
                    </li>
                );
            })}
        </ul>
    );
}

export default MobileViewCard;
